import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../pages/Login'
import Home from '../pages/Home' // Importação da exportação padrão do Home
import FullOffer from '../pages/FullOffer'
import Offers from '../pages/Offers'
import Portifolio from '../pages/Portifolio'
import Payments from '../pages/Payments'
import PurchaseConfirmation from '../pages/PurchaseConfirmation'

export function RouteApp() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/FullOffer" element={<FullOffer />} />
        <Route path="/Offers" element={<Offers />} />
        <Route path="/FullOffer/:id" element={<FullOffer />} />
        <Route path="/Portfolio" element={<Portifolio />} />
        <Route path="/Payments/:id" element={<Payments />} />
        <Route path="/confirmation/:id" element={<PurchaseConfirmation />} />
      </Routes>
    </BrowserRouter>
  )
}
