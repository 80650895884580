import React, { useState } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Government from '../assets/img/Government.png';
import PrivateEquity from '../assets/img/PrivateEquity.png';
import Realstate from '../assets/img/Realstate.png';
import commodities from '../assets/img/commodities.png';
import './listedbytyoe.css';
import NorthEastArrow from '../assets/img/NorthEastArrow.svg';

const items = [
    { id: 1, name: 'Government Bonds', image: Government, link: '/offers?category=government-bonds' },
    { id: 2, name: 'Private Equity', image: PrivateEquity, link: '/offers?category=private-equity' },
    { id: 3, name: 'Real Estate', image: Realstate, link: '/offers?category=real-estate' },
    { id: 4, name: 'Commodities', image: commodities, link: '/offers?category=commodities' },
];

const Listedbytype = () => {
    const [hoveredItem, setHoveredItem] = useState(null);
    const [lockedItem, setLockedItem] = useState(null);

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
        setLockedItem(item);
    };

    const handleMouseLeave = (item) => {
        if (lockedItem && lockedItem.id === item.id) {
            return;
        }
        setHoveredItem(null);
        setLockedItem(null);
    };

    return (
        <>
            <Container>
                <div className='Header'>Explore Asset Diversity</div>
                <Row>
                    <Col className='mainbox'>
                        <ListGroup>
                            {items.map(item => (
                                <Link to={item.link} key={item.id} className="no-underline">
                                    <ListGroup.Item
                                        className='flex-container'
                                        onMouseEnter={() => handleMouseEnter(item)}
                                        onMouseLeave={() => handleMouseLeave(item)}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            fontSize: '20px',
                                            borderTop: 'none',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                            borderBottom: '1px solid #E5E5EA',
                                            padding: '27px',
                                            textDecoration: 'none',
                                            color: (hoveredItem && hoveredItem.id === item.id) || (lockedItem && lockedItem.id === item.id) ? 'gray' : 'black'
                                        }}
                                    >
                                        {item.name}
                                        <div><img src={NorthEastArrow} className='arrow' alt="arrow pointing northeast" /></div>
                                    </ListGroup.Item>
                                </Link>
                            ))}
                        </ListGroup>
                    </Col>
                    <Col md={3}>
                        {(hoveredItem || lockedItem) && (
                            <div className="image-container">
                                <img src={(hoveredItem || lockedItem).image} alt={(hoveredItem || lockedItem).name} className="img-fluid" />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Listedbytype;