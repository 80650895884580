import React from 'react';
import './LP-cards.css';

const CardLP = ({ title, text }) => {
  return (
    <div className="card custom-card">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <hr className="divider" />
        <p className="card-text description">
          {text}
        </p>
      </div>
    </div>
  );
}

export default CardLP;