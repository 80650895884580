import React from 'react';
import { useNavigate } from 'react-router-dom';
import leftarrow from '../assets/img/leftarrow.svg'

const GoBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);  // Vai para a página anterior
  };

  return (
    <div onClick={handleGoBack} className="backButton">
     <img src={leftarrow} alt="left arrow" /> Back
    </div>
  );
};

export default GoBackButton;
