import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import logoKhiza from '../assets/img/LOGO_KHIZA-19_1.svg';

const FooterComponent = () => {
  return (
    <footer className="custom-footer">
      <Container fluid>
        <Row className="align-items-center">
          <Col className="text-left footer-left">
            © 2024 Speck. All rights reserved.
          </Col>
          <Col className="text-right footer-right d-flex justify-content-end align-items-center">
            <span className="partner-text">Partner</span>
            <img src={logoKhiza} alt="Khiza Logo" className="khiza-logo"/>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;