import React from 'react';
import './UnveilingOpportunites.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function UnveilingOpportunites() {
  return (
    <div className='test'>
      <Row className='align-items-center'>
        <Col md={12} className='Unveiling'>
          Unveiling Exclusive <span>Investment</span> 
          <p>Opportunities</p>
        </Col>
        <Col md={8} className='UnveilingDescription'>
          {`Traditional investments in real-world assets (RWAs) often need to overcome barriers due to significant capital requirements and intricate regulations. Speck Finance democratizes access to
           these opportunities by leveraging tokenized assets, enabling broader participation with 
          lower entry points. Our platform seamlessly caters to both individual and institutional investors, encompassing a diverse range from self-directed enthusiasts and cryptocurrency aficionados
           to seasoned investment advisors collaborating with asset management firms, brokerage houses, and banks.`}
        </Col>
      </Row>
    </div>

  );
}

export default UnveilingOpportunites;