import React from 'react';
import './InvestInTheFuture.css';
import BuyTokensButton from "../components/Buy-tokens-button";


function InvestInTheFuture() {
  return (
    <div className='align'>
       <div className='Maintext'>Invest in the future with Speck Finance</div>
       <div className='align SecondaryText' >Simplify your investment strategies, diversify your portfolio, and enjoy enhanced accessibility. 
        <div></div>Click and start today to transform your financial potential!</div>
       <div className='addMargin'>
       <BuyTokensButton  ></BuyTokensButton>
       </div>
    </div>
  );
}

export default InvestInTheFuture;














