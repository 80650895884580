// Sidebar.js
import React from 'react'
import { Nav } from 'react-bootstrap'
import './SideBarc.css' // Importar CSS para estilização personalizada
import logo from '../assets/img/mainLogo.svg' // Importar a imagem do logo
import homeIcon from '../assets/img/homebuttonsidebar.svg' // Importar ícone para Home
import configIcon from '../assets/img/configbutton.svg' // Importar ícone para Config
import portfolioIcon from '../assets/img/portifoliobutton.svg' // Importar ícone para Portfolio
import { useUser } from '../hooks/use-user.hook'

const SideBarc = () => {
  const user = useUser()

  return (
    <div className="sidebar">
      {/* Logo */}
      <div className="sidebar-logo">
        <img src={logo} alt="Logo" className="logo-img" />
      </div>
      {/* Links de navegação */}
      <Nav className="flex-column">
        <Nav.Link href="/home">
          <img src={homeIcon} alt="Home" className="sidebar-icon" />
        </Nav.Link>
        <Nav.Link href="/portfolio">
          <img src={portfolioIcon} alt="Portfolio" className="sidebar-icon" />
        </Nav.Link>
        <Nav.Link href="#config" className="config-link">
          <div className="config">
            <img src={configIcon} alt="Config" className="sidebar-configicon" />
          </div>
          {user && (
            <div className="profile">
              <img
                src={user?.picture}
                alt={`${user?.given_name}'s profile`}
                className="sidebar-configicon"
              />
            </div>
          )}
        </Nav.Link>
      </Nav>
    </div>
  )
}
export default SideBarc
