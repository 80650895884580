import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './FullOffer.css'
import SideBarc from '../components/SideBarc'
import fetch from 'unfetch'

import LoginButton from '../components/LoginButton'
import GoBackButton from '../components/GoBackButton'
import { useApi } from '../hooks/use-api.hook'
import { useUser } from '../hooks/use-user.hook'

const FullOffer = () => {
  const { id } = useParams() // Get the id parameter from the URL

  const navigate = useNavigate()

  const user = useUser()

  const [submitting, setSubmitting] = useState(false)
  const [minInvestiment, setMinInvestiment] = useState()

  const { data, isLoading } = useApi(
    `/tokens/${id}.json?page=1&per_page=10`,
    `tokens-${id}`,
    Boolean(id > 0 ? true : false),
    false
  )

  function handleInvestimentClick() {
    if (!user) {
      navigate(`/login?re=FullOffer/${id}`)
    } else {
      setSubmitting(true)
      fetch('https://0g6o56vy4j.execute-api.us-east-2.amazonaws.com/transactions.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify({
          transaction: {
            token_price: data.shareprice,
            token_id: data.id,
            total_amount: minInvestiment,
            token_quantity: 1
          }
        })
      })
        .then((r) => r.json())
        .then((response) => {
          if (response) {
            navigate(`/payments/${response.id}`)
          }
        })
        .catch((error) => {
          setSubmitting(false)
        })
    }
  }

  return (
    <>
      {isLoading && <div>Loading...</div>}
      <SideBarc />
      <div className="navbar1">
        <GoBackButton></GoBackButton>
        <LoginButton />
      </div>
      <Container className="py-4 all">
        {data ? (
          <Row>
            <div className="FirstPartOfThePage">
              {/* Card Tags */}
              <div className="card-tags mb-3">
                {JSON.parse(data.cardtags).map((tag, index) => (
                  <span key={index} className="badge custom-badge mr-2">
                    {tag}
                  </span>
                ))}
              </div>

              {/* Title and Description */}
              <div className="mt-3 primarytextandcontinuetoinvest">
                <div>
                  <h1 className="font-weight-bold">{data.Tokenname}</h1>
                  <p className="text-muted">{data.tokendescription}</p>

                  {/* Min. Investment */}
                  <div className="min-investment mt-2">
                    <p className="text-muted">Min. investment</p>
                    <p>
                      <strong>${Number(data.mininvestiment).toFixed(2)}</strong>
                    </p>
                  </div>
                </div>

                <div>
                  <div id="investmentAmount">
                    <input
                      type="number"
                      placeholder={`$${Number(data.mininvestiment).toFixed(2)}`}
                      className="mb-3 "
                      value={minInvestiment}
                      onChange={(e) => setMinInvestiment(Number(e.target.value))}
                    />
                    <Button
                      variant="dark"
                      className="btn-block custom-button"
                      onClick={handleInvestimentClick}
                      disabled={submitting}
                    >
                      Continue Investment
                    </Button>
                  </div>
                </div>
              </div>

              {/* Grey Dividing Line */}
              <hr className="my-4 border-grey" />
            </div>

            {/* Second Part of the Page */}
            <div className="SecondPartOfThePage">
              {/* Overview Section */}
              <div className="overview-section">
                <h2 className="font-weight-bold">Overview</h2>

                {/* Assets Under Management */}
                <div className="">
                  <p className="text-muted">Assets Under Management</p>
                  <p>
                    <strong>${Number(data.assetsundermanagement).toFixed(2)} USD</strong>
                  </p>
                </div>

                {/* Nav */}
                <div className="">
                  <p className="text-muted">Nav as of 28-May-2024</p>
                  <p>
                    <strong>${data.nav} USD</strong>
                  </p>
                </div>

                {/* Fund Documents */}
                <div className="fund-documents">
                  <p>
                    <strong>Fund documents</strong>
                  </p>
                  <p className="text-muted limited-width">
                    {data.tokensupporttext}
                    <br />
                    {data.sellerdescription}
                  </p>
                </div>

                {/* Schedule a Meeting Button */}
                <Button variant="light" className="custom-schedule-button">
                  Schedule a meeting
                </Button>
              </div>

              {/* Key Facts Section */}
              <div className="key-facts-section">
                <h2 className="font-weight-bold">Key Facts</h2>
                <div className="key-fact light-grey-bg">
                  <p className="text-muted">Inception</p>
                  <p>
                    <strong>{data.inception}</strong>
                  </p>
                </div>
                <div className="key-fact white-bg">
                  <p className="text-muted">Strategy</p>
                  <p>
                    <strong>{data.strategy}</strong>
                  </p>
                </div>
                <div className="key-fact light-grey-bg">
                  <p className="text-muted">NAV type</p>
                  <p>
                    <strong>{data.navtype}</strong>
                  </p>
                </div>
                <div className="key-fact white-bg">
                  <p className="text-muted">Share price</p>
                  <p>
                    <strong>${data.shareprice} USD</strong>
                  </p>
                </div>
                <div className="key-fact light-grey-bg">
                  <p className="text-muted">Subscription deadline</p>
                  <p>
                    <strong>{data.subscriptiondeadline}</strong>
                  </p>
                </div>
                <div className="key-fact white-bg">
                  <p className="text-muted">Redemption deadline</p>
                  <p>
                    <strong>{data.redemptiondeadline}</strong>
                  </p>
                </div>
                <div className="key-fact light-grey-bg">
                  <p className="text-muted">Domicile</p>
                  <p>
                    <strong>{data.domicile}</strong>
                  </p>
                </div>
                <div className="key-fact white-bg">
                  <p className="text-muted">Base currency</p>
                  <p>
                    <strong>{data.basecurrent}</strong>
                  </p>
                </div>
                <div className="key-fact light-grey-bg">
                  <p className="text-muted">Subscription frequency</p>
                  <p>
                    <strong>{data.subscriptiontype}</strong>
                  </p>
                </div>
              </div>
            </div>
          </Row>
        ) : (
          <div>No data available.</div>
        )}
      </Container>
    </>
  )
}

export default FullOffer
