import { useCallback } from 'react'

const useUser = () => {
  const user = useCallback(() => {
    const userInfo = JSON.parse(sessionStorage.getItem('sk_user'))
    if (userInfo && userInfo.exp < Date.now() / 1000) {
      sessionStorage.removeItem('sk_user')
      return null
    } else {
      return userInfo
    }
  })
  return user()
}

export { useUser }
