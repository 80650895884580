import React from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate from react-router-dom
import './Card-Tokens.css' // Assuming you have your own CSS file for CardToken
const CardToken = ({ investment }) => {
  const navigate = useNavigate() // Initialize useNavigate
  const redirectToFullOffer = (id) => {
    navigate(`/FullOffer/${id}`) // Navigate to FullOffer with the correct id
  }
  if (!investment) {
    return <div>Loading...</div>
  }

  return (
    <div className="card custom-carD Card" onClick={() => redirectToFullOffer(investment.id)}>
      <div className="card-body">
        <h5 className="card-title">{investment.tokenname}</h5>
        <p className="card-text">{investment.tokensupporttext}</p>
        <hr className="divider" />
        <div className="tags mb-3">
          {JSON.parse(investment.cardtags).map((tag, index) => (
            <span key={index} className="badge badge-light">
              {tag}
            </span>
          ))}
        </div>
        <p className="card-text description">{investment.tokendescription}</p>
        <p className="card-text min-investment">Min. investment</p>
        <p>
          <strong>${Number.parseFloat(investment.mininvestiment).toFixed(2)}</strong>
        </p>
      </div>
      <div className="card-footer">
        {JSON.parse(investment['badge_secondary_tag']).map((tag, index) => (
          <span key={index} className="badge badge-secondary">
            <div className="Tag">{tag}</div>
          </span>
        ))}
      </div>
    </div>
  )
}
export default CardToken
