import React, { useState } from 'react'
import { useStripe, useElements, CardElement, PaymentElement } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'

const CheckoutForm = ({ intentSecret, idToken, amount }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [paymentMethod, setPaymentMethod] = useState('card')
  const [saveCard, setSaveCard] = useState(false)
  const [errorStripe, setErrorStripe] = useState()

  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()

    setErrorStripe(null)

    const cardElement = elements.getElement('card')

    try {
      const result = await stripe.confirmCardPayment(intentSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: 'test'
          }
        }
      })
      // const result = await stripe.confirmPayment({
      //   elements: cardElement,
      //   clientSecret: data.client_secret,
      //   confirmParams: {
      //     return_url: `/confirmation/${data.id}`
      //   }
      // })
      //return_url:

      if (result.error) {
        console.log(result)
        setErrorStripe(result.error.message)
      } else {
        navigate(`/confirmation/${idToken}`)
      }
    } catch (error) {
      setErrorStripe(error.message)
    }
  }

  return (
    <>
      {/* <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button disabled={!stripe}>Submit</button>
      </form> */}
      <form onSubmit={handleSubmit} className="container mt-5">
        <h2>Payment</h2>
        <hr />
        <div className="mb-3">
          <label className="form-label">Pay With:</label>
          <div>
            <input
              type="radio"
              id="card"
              name="paymentMethod"
              value="card"
              checked={paymentMethod === 'card'}
              onChange={() => setPaymentMethod('card')}
            />
            <label htmlFor="card" className="ms-2">
              Card
            </label>
            <input
              type="radio"
              id="bank"
              name="paymentMethod"
              value="bank"
              className="ms-3"
              onChange={() => setPaymentMethod('bank')}
            />
            <label htmlFor="bank" className="ms-2">
              Bank
            </label>
            <input
              type="radio"
              id="transfer"
              name="paymentMethod"
              value="transfer"
              className="ms-3"
              onChange={() => setPaymentMethod('transfer')}
            />
            <label htmlFor="transfer" className="ms-2">
              Transfer
            </label>
          </div>
        </div>

        {paymentMethod === 'card' && (
          <>
            <div className="mb-3">
              <label className="form-label">Card Number</label>
              <CardElement className="form-control" />
            </div>

            {/* <div className="mb-3 d-flex">
              <div className="me-3">
                <label className="form-label">Expiration Date</label>
                <input type="text" className="form-control" placeholder="MM/YY" />
              </div>
              <div>
                <label className="form-label">CVV</label>
                <input type="text" className="form-control" placeholder="CVC" />
              </div>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={saveCard}
                onChange={() => setSaveCard(!saveCard)}
                id="saveCard"
              />
              <label className="form-check-label" htmlFor="saveCard">
                Save card details
              </label>
            </div> */}
          </>
        )}

        <button className="btn btn-outline-secondary w-100 mb-3" type="submit" disabled={!stripe}>
          Pay USD {`${Number(amount).toFixed(2)}`}
        </button>
        <p className="text-muted small">
          Your personal data will be used to process your order, support your experience throughout
          this website, and for other purposes described in our privacy policy.
        </p>
      </form>
      <div className="container mt-4 alert alert-danger" role="alert" hidden={!errorStripe}>
        {errorStripe ?? <p>{errorStripe}</p>}
      </div>
    </>
  )
}

export default CheckoutForm
