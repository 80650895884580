import React from 'react'
import { useNavigate } from 'react-router-dom'
import './LoginButton.css'
import { useUser } from '../hooks/use-user.hook'

const LoginButton = () => {
  const user = useUser()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/login')
  }

  return (
    <>
      {user ? (
        <div>
          <p>Welcome, {user.given_name}</p>
        </div>
      ) : (
        <button className="pill-button1" onClick={handleClick}>
          Log in
        </button>
      )}
    </>
  )
}

export default LoginButton
