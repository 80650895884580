import React from 'react';
import './Primary-offerings.css';
import NorthEastArrow from '../assets/img/NorthEastArrow.svg'


const PrimaryOfferings = () => {
    return (
       <>
        <div className='Primaryofferings'>
        Primary offerings <img src={NorthEastArrow} alt='arrow pointing north east'></img>
        </div>
       </>
    );
  }

 
  
  export default PrimaryOfferings;