import React from 'react';
import './Text-Main.css';

const MainText = () => {
    return (
       <>
       <body>
         <div className='FirstText'>Step into the <span>Future</span> with Tokenized Real-World Assets</div>
         <div className='SecondText'>Transform your investments: easier access, smarter strategies, more possibilities</div>
       </body>
       </>
    );
  }

 
  
  export default MainText;